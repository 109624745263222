var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"frontend-platform@v20241011T094136-otodompl"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CURRENT_SITE_CODE } from '@config/siteCode';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { init, setTags } from '@sentry/nextjs';

const { env, isSentryEnabled, sentryDsn, sentryEnvironment, sentrySampleRateClient, sentryTracesSampleRateClient } =
    getPublicEnvConfig();
const IGNORED_MESSAGE_LIST = [
    // Reason of disabling: When the page loads, the refresh button changes to cross button, now if some api request
    // is in progress during this page loading time and the user click this cross button, then iOS chrome/safari throws
    // this error.
    // Source: https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    // Error related to ninja script loading
    // Sources: gtm related marked as ?(<anonymous>), ro/contul-meu/* > InjectedScript
    'ReferenceError: ninjaPV is not defined',
    // Error related to cookielaw script and safari browser
    // Details: https://git.naspersclassifieds.com/olxeu/realestate/frontend-platform/-/merge_requests/6376
    // Source: scripttemplates\/[\d.]*\/otBannerSdk\.js
    // Docs: https://developer.onetrust.com/onetrust/docs/performance-availability-cookie-script#description
    // Note: Case when failed to load Domain.json file - otBannerSdk.js requires this file
    "TypeError: undefined is not an object (evaluating 'r.DomainData')",
    "TypeError: Cannot read properties of undefined (reading 'DomainData')",
    // Note: Case when trying to access DOM elements for cookie banner
    "TypeError: null is not an object (evaluating 'this.selector.appendChild')", // errors related to 3rd party scripts, no details which one, no stack trace available
    "TypeError: null is not an object (evaluating 'nodeList[i].getAttribute('rel').startsWith')", // errors related to 3rd party scripts, no details which one, no stack trace available
    "ReferenceError: Can't find variable: _AutofillCallbackHandler", // client side errors for auth
    "TypeError: Cannot read properties of undefined (reading '_avast_submit')", // 3rd party integration error
    'AbortError: The user aborted a request.', // an error when request is aborted
    'Error: AbortError: The user aborted a request.', // an error when request is aborted
    'ResizeObserver loop limit exceeded.', // we are not able to fix it, and it does not affect the end user
    'TypeError: Load failed', // an issue with preloading images in iOS
    'UnhandledRejection: Non-Error promise rejection captured with value: Inconsistent Data', // errors related to 3rd party ads
    'UnhandledRejection: Non-Error promise rejection captured with value: undefined', // errors related to 3rd party ads
    'UnhandledRejection: Non-Error promise rejection captured with value: false', // errors related to 3rd party ads
    "SyntaxError: Failed to execute 'querySelectorAll' on 'Document': ':is([id*='gpt-'])' is not a valid selector.", // errors related to 3rd party scripts
    "TypeError: Cannot read properties of undefined (reading 'nativeBack')", // errors related to 3rd party scripts
    "ReferenceError: Can't find variable: IFrameMessageHandler", // can't reproduce (tried SafariServices and webKit in swift demo app) as it's facebook app webview - 3rd party
    'ReferenceError: globalThis is not defined', // triggered by unsupported browsers details in the issue: https://olx-europe-re.sentry.io/issues/5419484560/activity/?project=4506263525982208&referrer=tag-distribution-meter
];

setTags({
    siteCode: CURRENT_SITE_CODE,
    loadStatus: 'loading',
});

window.addEventListener('load', () => {
    setTags({ loadStatus: 'page_loaded' });
});

init({
    enabled: Boolean(sentryDsn) && isSentryEnabled,
    environment: sentryEnvironment || env,
    dsn: sentryDsn, // when undefined, sentry is disabled by default
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE, // prepared in next.config.js
    // Transactions should be sampled only by tracesSampleRate or tracesSampler.
    // The sampleRate configuration is used for error events and should not apply to transactions.
    // The limit is 1M for all projects per month
    sampleRate: sentrySampleRateClient,
    tracesSampleRate: sentryTracesSampleRateClient, // Limit is ~100_000 events for all sites per month
    ignoreErrors: IGNORED_MESSAGE_LIST,
    /**
     * Regex pattern match:
     * - https://otodom.pl/
     * - https://storia.ro/
     * - https://www.imovirtual.com/
     * - https://stg.otodom.pl/
     * - https://stg.storia.ro/
     * - https://stg.imovirtual.com/
     *
     * Can't find imovirtual.pt domain on the list?
     * We do have a redirect to imovirtual.com - we don't need to worry about it.
     */
    allowUrls: [/https?:\/\/(\w*\.)*?(imovirtual\.com|otodom\.pl|storia\.ro)/],
    beforeBreadcrumb: (breadcrumb, hint) => {
        const { category, data, level, message, type } = breadcrumb;
        // Filter out new relic requests
        if (type === 'http' && (data?.url?.includes('.newrelic.com/') || data?.url?.includes('.nr-data.net/'))) {
            return null;
        }
        // Filter out 'Missing translations'
        if (category === 'log' && level === 'warning' && message?.includes('Missing translation')) {
            return null;
        }

        // extract clickable UI element accessible name
        if (hint && category === 'ui.click') {
            const { target } = hint.event;

            // aria-label, id, input name are already in breadcrumb.message

            switch (true) {
                case !!target.dataset?.cy: {
                    breadcrumb.data = { 'data-cy': target.dataset.cy };
                    break;
                }
                case !!target.title: {
                    breadcrumb.data = { title: target.title };
                    break;
                }
                case !!target.textContent: {
                    breadcrumb.data = { textContent: target.textContent };
                    break;
                }
                default: {
                    return breadcrumb;
                }
            }
        }

        return breadcrumb;
    },
});
